<template>
  <div :style="style" :class="`${iconClass ? iconClass : ''} ${setSize}`">
    <svg v-if="icon === 'spinner-solid'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        :d="
          `M 16 3 C 14.34375 3 13 4.34375 13 6 C 13 7.65625 14.34375 9 16 9 C 17.65625 9 19 ` +
          `7.65625 19 6 C 19 4.34375 17.65625 3 16 3 Z M 8.9375 6.4375 C 7.558594 6.4375 6.4375 ` +
          `7.558594 6.4375 8.9375 C 6.4375 10.316406 7.558594 11.4375 8.9375 11.4375 C 10.316406 ` +
          `11.4375 11.4375 10.316406 11.4375 8.9375 C 11.4375 7.558594 10.316406 6.4375 8.9375 ` +
          `6.4375 Z M 23.0625 7.9375 C 22.511719 7.9375 22.0625 8.386719 22.0625 8.9375 C 22.0625 ` +
          `9.488281 22.511719 9.9375 23.0625 9.9375 C 23.613281 9.9375 24.0625 9.488281 24.0625 ` +
          `8.9375 C 24.0625 8.386719 23.613281 7.9375 23.0625 7.9375 Z M 6 13.75 C 4.757813 13.75 ` +
          `3.75 14.757813 3.75 16 C 3.75 17.242188 4.757813 18.25 6 18.25 C 7.242188 18.25 8.25 ` +
          `17.242188 8.25 16 C 8.25 14.757813 7.242188 13.75 6 13.75 Z M 26 14.75 C 25.308594 14.75 ` +
          `24.75 15.308594 24.75 16 C 24.75 16.691406 25.308594 17.25 26 17.25 C 26.691406 17.25 ` +
          `27.25 16.691406 27.25 16 C 27.25 15.308594 26.691406 14.75 26 14.75 Z M 8.9375 21.0625 ` +
          `C 7.832031 21.0625 6.9375 21.957031 6.9375 23.0625 C 6.9375 24.167969 7.832031 25.0625 ` +
          `8.9375 25.0625 C 10.042969 25.0625 10.9375 24.167969 10.9375 23.0625 C 10.9375 21.957031 ` +
          `10.042969 21.0625 8.9375 21.0625 Z M 23.0625 21.5625 C 22.234375 21.5625 21.5625 ` +
          `22.234375 21.5625 23.0625 C 21.5625 23.890625 22.234375 24.5625 23.0625 24.5625 C ` +
          `23.890625 24.5625 24.5625 23.890625 24.5625 23.0625 C 24.5625 22.234375 23.890625 ` +
          `21.5625 23.0625 21.5625 Z M 16 24.25 C 15.035156 24.25 14.25 25.035156 14.25 26 C 14.25 ` +
          `26.964844 15.035156 27.75 16 27.75 C 16.964844 27.75 17.75 26.964844 17.75 26 C 17.75 ` +
          `25.035156 16.964844 24.25 16 24.25 Z`
        "
      />
    </svg>
    <svg v-else-if="icon === 'expand-solid'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        :d="
          `M 4 4 L 4 13 L 6 13 L 6 6 L 13 6 L 13 4 L 4 4 z M 19 4 L 19 6 L 26 6 L 26 13 L 28 ` +
          `13 L 28 4 L 19 4 z M 4 19 L 4 28 L 13 28 L 13 26 L 6 26 L 6 19 L 4 19 z M 26 19 L 26 26 L ` +
          `19 26 L 19 28 L 28 28 L 28 19 L 26 19 z`
        "
      />
    </svg>
    <svg
      v-else-if="icon === 'compress-solid'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
    >
      <path
        :d="
          `M 11 4 L 11 11 L 4 11 L 4 13 L 13 13 L 13 4 L 11 4 z M 19 4 L 19 13 L 28 13 L 28 ` +
          `11 L 21 11 L 21 4 L 19 4 z M 4 19 L 4 21 L 11 21 L 11 28 L 13 28 L 13 19 L 4 19 z M 19 19 ` +
          `L 19 28 L 21 28 L 21 21 L 28 21 L 28 19 L 19 19 z`
        "
      />
    </svg>
    <svg v-else-if="icon === 'cube'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        :d="
          `M 16 4 L 15.625 4.15625 L 9.625 6.5625 L 9 6.8125 L 9 13.53125 L 3.59375 15.875 ` +
          `L 3 16.15625 L 3 24.21875 L 3.5 24.53125 L 9.5 27.875 L 9.96875 28.125 L 10.4375 27.90625 ` +
          `L 16 25.125 L 21.5625 27.90625 L 22.03125 28.125 L 22.5 27.875 L 28.5 24.53125 L ` +
          `29 24.21875 L 29 16.15625 L 28.40625 15.875 L 23 13.53125 L 23 6.8125 L 22.375 6.5625 L ` +
          `16.375 4.15625 Z M 16 6.1875 L 19.28125 7.46875 L 16 8.75 L 12.71875 7.46875 Z M 11 ` +
          `8.9375 L 15 10.46875 L 15 15.34375 L 11 13.5625 Z M 21 8.9375 L 21 13.5625 L 17 15.34375 ` +
          `L 17 10.46875 Z M 10 15.3125 L 13.625 16.90625 L 10 18.6875 L 6.375 16.875 Z M 22 15.3125 ` +
          `L 25.625 16.875 L 22 18.6875 L 18.375 16.90625 L 19.5 16.40625 Z M 5 18.40625 L 9 ` +
          `20.40625 L 9 25.3125 L 5 23.0625 Z M 27 18.40625 L 27 23.0625 L 23 25.3125 L 23 20.40625 ` +
          `Z M 15 18.46875 L 15 23.375 L 11 25.375 L 11 20.40625 Z M 17 18.46875 L 21 20.40625 L 21 ` +
          `25.375 L 17 23.375 Z`
        "
      />
    </svg>
    <svg v-else-if="icon === 'trash'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        :d="
          `M 15 4 C 14.476563 4 13.941406 4.183594 13.5625 4.5625 C 13.183594 4.941406 13 ` +
          `5.476563 13 6 L 13 7 L 7 7 L 7 9 L 8 9 L 8 25 C 8 26.644531 9.355469 28 11 28 L 23 28 C ` +
          `24.644531 28 26 26.644531 26 25 L 26 9 L 27 9 L 27 7 L 21 7 L 21 6 C 21 5.476563 ` +
          `20.816406 4.941406 20.4375 4.5625 C 20.058594 4.183594 19.523438 4 19 4 Z M 15 6 L 19 6 ` +
          `L 19 7 L 15 7 Z M 10 9 L 24 9 L 24 25 C 24 25.554688 23.554688 26 23 26 L 11 26 C ` +
          `10.445313 26 10 25.554688 10 25 Z M 12 12 L 12 23 L 14 23 L 14 12 Z M 16 12 L 16 23 L ` +
          `18 23 L 18 12 Z M 20 12 L 20 23 L 22 23 L 22 12 Z`
        "
      />
    </svg>
    <svg v-else-if="icon === 'shapes'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        :d="
          `M 22.357422 3.8632812 L 16.189453 15 L 28.525391 15 L 22.357422 3.8632812 z M ` +
          `22.357422 7.9902344 L 25.130859 13 L 19.583984 13 L 22.357422 7.9902344 z M 9 16 C ` +
          `5.691 16 3 18.691 3 22 C 3 25.309 5.691 28 9 28 C 12.309 28 15 25.309 15 22 C 15 ` +
          `18.691 12.309 16 9 16 z M 17 17 L 17 28 L 28 28 L 28 17 L 17 17 z M 9 18 C 11.206 18 ` +
          `13 19.794 13 22 C 13 24.206 11.206 26 9 26 C 6.794 26 5 24.206 5 22 C 5 19.794 6.794 18 ` +
          `9 18 z M 19 19 L 26 19 L 26 26 L 19 26 L 19 19 z`
        "
      />
    </svg>
    <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        :d="
          `M 16 4 C 9.382813 4 4 9.382813 4 16 C 4 22.617188 9.382813 28 16 28 C 22.617188 ` +
          `28 28 22.617188 28 16 C 28 9.382813 22.617188 4 16 4 Z M 16 6 C 21.535156 6 26 ` +
          `10.464844 26 16 C 26 21.535156 21.535156 26 16 26 C 10.464844 26 6 21.535156 6 16 C 6 ` +
          `10.464844 10.464844 6 16 6 Z M 16 10 C 13.800781 10 12 11.800781 12 14 L 14 14 C 14 ` +
          `12.882813 14.882813 12 16 12 C 17.117188 12 18 12.882813 18 14 C 18 14.765625 17.507813 ` +
          `15.445313 16.78125 15.6875 L 16.375 15.8125 C 15.558594 16.082031 15 16.863281 15 ` +
          `17.71875 L 15 19 L 17 19 L 17 17.71875 L 17.40625 17.59375 C 18.945313 17.082031 20 ` +
          `15.621094 20 14 C 20 11.800781 18.199219 10 16 10 Z M 15 20 L 15 22 L 17 22 L 17 20 Z`
        "
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "Icons",
  data() {
    return {
      style: null,
    };
  },
  props: {
    icon: String,
    iconClass: String,
    iconStyle: {
      type: Object,
    },
    size: {
      type: String,
      default: "middle",
      validator: function (value) {
        return ["small", "middle", "large", "extralarge"].indexOf(value) !== -1;
      },
    },
  },
  watch: {
    iconStyle: function (val) {
      this.style = val;
    },
  },
  computed: {
    setSize() {
      return this.size ? `icon__${this.size}` : "icon__middle";
    },
  },
};
</script>
